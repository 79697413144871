@import url(https://fonts.googleapis.com/css?family=Raleway:300);

:root {
  --btn-border: 1px solid rgba(255, 255, 255, 0.2);
  --btn-bg: transparent; /* #333 */
  --btn-shadow: 1px 1px 25px 10px rgba(255, 255, 255, 0.5);
  --btn-text-color: #f4f4f4;
  --shine-degree: 120deg;
  --shine-color: rgba(255, 255, 255, 0.2);
  --shine-effect: linear-gradient(
    var(--shine-degree),
    transparent,
    var(--shine-color),
    transparent
  );
  --shine-transition: all 0.65s ease-in-out;
}

.btn {
  position: relative;
  overflow: hidden; /* debug */
  font-size: 1.1rem;
  padding: 1rem 3rem;
  border-radius: 4px;
  text-decoration: none;
  border: var(--btn-border);
  color: var(--btn-text-color);
  background: var(--btn-bg);
}

.btn::before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--shine-effect);
}

.btn-hover::before {
  left: -100%;
  transition: var(--shine-transition);
}

.btn:hover {
  box-shadow: var(--btn-shadow);
}

.btn:hover::before {
  left: 100%;
}

.btn-anim::before {
  animation: shine 5s ease-in-out infinite;
}

@keyframes shine {
  0% {
    left: -100%;
    transition-property: left;
  }
  26%,
  100% {
    left: 100%;
    transition-property: left;
  }
}

.btn-alt {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 60px;
  font-size: 1.1rem;
  position: relative;
  overflow: hidden; /* debug */
  border-radius: 4px;
  text-decoration: none;
  border: var(--btn-border);
  color: var(--btn-text-color);
  background: var(--btn-bg);
}

.btn-alt::before,
.btn-alt::after {
  content: "";
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
}

.btn-alt::before {
  background: rgba(255, 255, 255, 0.5);
  width: 60px;
  left: 0;
  filter: blur(30px);
  animation: shine-alt1 5s ease-in-out infinite;
}

.btn-alt::after {
  background: rgba(255, 255, 255, 0.2);
  width: 30px;
  left: 30px;
  filter: blur(5px);
  animation: shine-alt2 5s ease-in-out infinite;
}

@keyframes shine-alt1 {
  0% {
    opacity: 0.5;
    transform: translateX(-100px) skewX(-15deg);
    transition-property: opacity, transform;
  }
  26%,
  100% {
    opacity: 0.6;
    transform: translateX(300px) skewX(-15deg);
    transition-property: opacity, transform;
  }
}

@keyframes shine-alt2 {
  0% {
    opacity: 0;
    transform: translateX(-100px) skewX(-15deg);
    transition-property: opacity, transform;
  }
  26%,
  100% {
    opacity: 1;
    transform: translateX(300px) skewX(-15deg);
    transition-property: opacity, transform;
  }
}

/* Reset */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* Body styling */
body {
  background: #111;
  font-family: "Raleway", Arial, Helvetica, sans-serif;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
