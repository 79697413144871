.couponusedChild,
.couponusedItem {
  position: absolute;
  top: 0;
  left: 0;
  width: 311px;
}
.couponusedChild {
  border-radius: 16px;
  background-color: #f2f4f5;
  height: 352px;
}
.couponusedItem {
  border-radius: 16px 16px 0 0;
  height: 138px;
  object-fit: cover;
}
.offerText {
  top: 225px;
  left: 24px;
  line-height: 24px;
  font-weight: 600;
}
.offer,
.offerText,
.text {
  position: absolute;
}
.offer {
  top: 169px;
  left: 24px;
  font-size: 40px;
  line-height: 56px;
}
.text {
  top: calc(50% - 12px);
  left: calc(50% - 107.5px);
}
.contentCopyIcon,
.controlsButtons {
  position: absolute;
  top: 9px;
  left: 211px;
  width: 30px;
  height: 30px;
}
.controlsButtons {
  top: 280px;
  left: 24px;
  border-radius: 48px;
  background-color: #d6d6d6;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05) inset;
  width: 263px;
  height: 48px;
  cursor: pointer;
  font-size: 20px;
  color: #222;
}
.couponused {
  top: 281px;
  left: calc(50% - 156px);
  width: 311px;
  height: 352px;
  color: #090a0a;
}
.couponused,
.div,
.viewTcs {
  position: absolute;
}
.viewTcs {
  top: 641px;
  left: 58px;
  font-size: 14px;
  line-height: 24px;
  font-family: Roboto;
  color: #565656;
}
.div {
  top: calc(50% - 6px);
  left: 30px;
  line-height: 16px;
  font-weight: 500;
}
.mobileSignalIcon {
  position: absolute;
  top: calc(50% - 1.5px);
  right: 69px;
  width: 18px;
  height: 10px;
}
.batteryIcon,
.wifiIcon {
  position: absolute;
  top: calc(50% - 3px);
  right: 48.73px;
  width: 15.27px;
  height: 10.97px;
}
.batteryIcon {
  top: calc(50% - 4px);
  right: 16.02px;
  width: 26.98px;
  height: 13px;
}
.nativeStatusBar {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  height: 50px;
  text-align: left;
}
.rynLogoIcon {
  position: absolute;
  right: 115px;
  bottom: 1px;
  border-radius: 260px;
  width: 41px;
  height: 41px;
  object-fit: cover;
}
.rynVpn {
  margin: 0;
  font-weight: 600;
}
.quizTime {
  margin: 0;
  font-size: 16px;
}
.rynVpnQuizContainer {
  position: absolute;
  top: 0;
  left: 49px;
  line-height: 19.5px;
}
.rynLogoParent {
  position: absolute;
  right: 136px;
  bottom: 805px;
  width: 156px;
  height: 42px;
  text-align: left;
  font-size: 24px;
}

